import React, { CSSProperties } from 'react';

import './ButtonWithIconAndText.scss';

export type ButtonWithIconAndTextProps = {
  text: string;
  onClick?(e?: any): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  style?: CSSProperties;
  icon: any;
};

// NOTE: Default background color is #2574FB

const ButtonWithIconAndText = ({
  text,
  onClick,
  className = '',
  background,
  disabled,
  style,
  icon,
}: ButtonWithIconAndTextProps) => (
  <button
    type="button"
    className={`button-with-icon-and-text ${className}`}
    onClick={onClick}
    tabIndex={0}
    style={{
      backgroundColor: background,
      ...(disabled ? { opacity: 0.3 } : {}),
      outline: 'none',
      ...style,
    }}
    disabled={disabled}
  >
    <img src={icon} alt="Icon" className="icon" />
    <span>{text}</span>
  </button>
);

export default ButtonWithIconAndText;
